<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'>
    <p class="firstStep">第一步：请点击下面的链接下载Excel模板，并按照模板填写信息</p>
    <p class="upload" @click="upload()">下载模板</p>
    <p>第二步： 导入Excel模板文件</p>
    <el-upload class="upload-demo" ref="upload" accept=".xls,.xlsx" action="#" :on-change="handleChange" :before-upload='beforeUpload' :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :auto-upload="false" :http-request="httpRequest">
      <span slot="trigger" class="upload">选取文件</span>
      <!-- :action="UploadUrl()" -->
    </el-upload>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  import axios from "axios";
  import XLSX from "xlsx";

  export default {
    props: {
      period: {
        type: String,
      },
      visible: {
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      Dialog,
    },
    data() {
      return {
        config: {
          top: '20vh',
          width: '21rem',
          title: '导入客户信息',
          center: true,
          btnTxt: ['导入', '取消'],
        },
        fileList: [],
        fileData: '',
        isdeling: false,
        tableData: [],
        status:  false,
        message : '请选择上传文件',
      };
    },
    methods: {
      httpRequest(param) {
        console.log(param.file);
      },
      handleChange(file, fileList){
        this.fileList = fileList.slice(-1);  //限定上传文件为一个
      },
      handleRemove(file, fileList) {
        this.fileData = '';
        this.status = false;
        this.message = '请选择上传文件';
      },
      beforeUpload(file, fileList) {
        let testFile = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testFile === 'xls'
        const extension2 = testFile === 'xlsx'
        const isLt1M = file.size / 1024 / 1024 < 1;

        if(!extension && !extension2) {
          this.message = '上传文件只能是xls或xlsx格式！'
          this.status = false;
        }else if (!isLt1M) {
          this.message = '上传Excel文件大小不能超过 1MB!'
          this.status = false;
        }else{
          let fd = new FormData()
          fd.append('file', file)
          fd.append("companyId", this.getToken('companyId'));
          this.fileData = fd;
          this.status = true;
        }
      },
      handlePreview(file) {
        console.log(file);
      },
      cancelDialogs(){
        this.cancelDialog();
        this.fileData = '';
        this.status = false;
        this.message = '请选择上传文件';
      },
      importConfirm() {
        this.tableData = [];
        this.$refs.upload.submit()
        if (this.fileData && this.fileData != ''&& this.status) {
          this.isdeling = true;
          this.$store.dispatch("importCustomer", this.fileData).then(res => {
            if (res.success) {
              this.cancelDialog();
              this.$parent.initTable();
              this.isdeling = false;
              this.fileList = [];
              this.fileData = '';
              this.status = false;
              this.message = '请选择上传文件';
              var reason = ''
              if ( res.data.failNum > 0 ) {
                var i = 0;
                res.data.failReason.forEach(each => {
                  i++;
                  if (i < 5) {
                    reason = reason.concat(`<p style="padding-top:0.5rem">第${each.index}行${each.reason},</p>`)
                  }
                  if (i == 5) {
                    reason = reason.concat(`<p style="padding-top:0.5rem">...</p>`)
                  }
                  this.tableData.push({
                    错误行: "第" + each.index + "行",
                    错误信息: each.reason
                  });
                })
                //有错误信息将错误信息通过excel导出
                if (this.tableData.length > 0) {
                  this.json2Excel(this.tableData)
                }
              }
              let msg = `<p>总条数：${res.data.totalNum} , 成功条数：${res.data.successNum} , 失败条数：${res.data.failNum}</p>${reason}`

              this.$message.success({
                duration: 3000,
                dangerouslyUseHTMLString: true,
                message: msg
              });
            } else {
              this.isdeling = false;
              this.fileList = [];
              this.fileData = '';
              this.cancelDialog();
              this.$message.error(res.msg);
            }
          })
        } else {
          this.isdeling = false;
          this.fileList = [];
          this.fileData = '';
          this.cancelDialog();
          this.$message.error(this.message);
          return;
        }
      },
      // importConfirm() {
      //   this.tableData = [];
      //   this.$refs.upload.submit()
      //   if (this.fileData && this.fileData != ''&& this.status) {
      //     this.isdeling = true;
      //     let  _this=this;
      //     this.$store.dispatch("importCustomer", this.fileData).then(res => {
      //       debugger
      //       if (res.success && res.data.isSuccess) {
      //         this.isdeling = false
      //         einvAlert.success("导入成功","导入成功"+res.data.successCount+"张");
      //       }else if(res.success){
      //       this.isdeling = false
      //       einvAlert.confirm(function (){
      //             _this.uploadError(res.data.logId)
      //           },"<span>导入成功"+res.data.successCount+"张</span>,"+"<span style='color: red'>导入失败"+res.data.failCount+"张</span>",
      //           '下载错误记录','取消');

      //     }else{
      //       this.isdeling = false
      //         einvAlert.error("提示",res.msg);
      //       //einvAlert.error("错误",res.msg);
      //     }
      //       this.fileData='';
      //       this.fileData=[];
      //       this.fileList = [];
      //       this.$parent.searchList()
      //       this.cancelDialog();
      //     })
      //   } else {
      //     this.isdeling = false;
      //     this.fileList = [];
      //     this.fileData = '';
      //     this.cancelDialog();
      //     einvAlert.error("提示",this.message);
      //     return;
      //   }
      // },

      upload() {  //下载客户信息导入模板
        let name = '客户信息导入模板.xls'
        axios({
          method: 'get',
          url: '/api/einvoice/customer/downTemplate',
          responseType: 'blob',
          params: {

          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          },
        }).then((res) => {
          let data = res.data;
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      },
      json2Excel(dataSource) {
        var wopts = {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        };
        var workBook = {
          SheetNames: ['Sheet1'],
          Sheets: {},
          Props: {}
        };
        //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
        //2、将数据放入对象workBook的Sheets中等待输出
        workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(dataSource)
        //3、XLSX.write() 开始编写Excel表格
        //4、changeData() 将数据处理成需要输出的格式
        this.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], { type: 'application/octet-stream' }), "错误信息")
      },
      changeData(s) {
        //如果存在ArrayBuffer对象(es6) 最好采用该对象
        if (typeof ArrayBuffer !== 'undefined') {
          //1、创建一个字节长度为s.length的内存区域
          var buf = new ArrayBuffer(s.length);
          //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
          var view = new Uint8Array(buf);
          //3、返回指定位置的字符的Unicode编码
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        } else {
          var buf = new Array(s.length);
          for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
      },
      saveAs(obj, fileName) {//当然可以自定义简单的下载文件实现方式
        var tmpa = document.createElement("a");
        tmpa.download = fileName ? fileName + '.xls' : new Date().getTime() + '.xls';
        tmpa.href = URL.createObjectURL(obj); //绑定a标签
        tmpa.click(); //模拟点击实现下载
        setTimeout(function () { //延时释放
          URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
        }, 100);
      },
      uploadError(val){
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '客户信息问题数据' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
         // einvAlert.error("提示",err)
        })
      }

    }
  };
</script>

<style lang="less" scoped>
  .firstStep {
    margin: 24px 0 12px;
  }
  .upload {
    cursor: pointer;
    color: #4f71ff;
  }
</style>
